import { JamezzApiResponse } from "../shared/api/common_types.ts";

export type ArrangementGroup = {
  id: number;
  name: string;

  view_options: {
    sortKey: number; // View only
    hidden: boolean; // View only
  };

  articleIdsInGroup: Record<string, boolean>;
  articlegroupIdsInGroup: Record<string, boolean>;

  maximum_per_ticket: number;
  maximum_order_rounds: number;
  max_per_round_per_cover: number;

  credit_cost: "outside_arrangements" | "no_cost" | number;
};

export type FetchTableStateResponse = {
  data: {
    table_items: TableItem[];
    is_open: boolean;
    table_identifier: string;
    updated_at: string;
    ticket: Ticket | null;
  };
} & JamezzApiResponse;
export type ArrangementRuleVisitLength = "duration" | "schedule" | "no_time";

/**
 * @property {"duration"|"schedule"|"no_time"} visit_length Determines how the visit length is calculated.
 *    - **duration** - Each visit has a set duration starting from when the app is first opened\
 *                   \
 *                   For example: A visit is up to 2 hours long
 *    - **schedule** - Visitors can make a reservation for a pre-set time block as configured in the back-office.\
 *                   \
 *                   For example: Block A is from 17:00 - 19:30 and block B is from 20:00 to 22:00 Visitors will have to leave when their block ends
 *    - **no_time** - The AYCE experience is not limited by time at all, visitors are free to stay for as long as they want
 */
export interface ArrangementResponse {
  id: number;

  name: string;

  arrangement_products: {
    id: number;
    arrangement_id: number;
    product_id: number;
  }[];
  arrangement_arrangement_groups: {
    id: number;
    arrangement_id: number;
    arrangement_group_id: number;
  }[];

  max_number_of_items_per_round_per_cover: number;
  minutes_between_every_round: number;
  max_rounds: number;
  max_time_in_minutes: number;
  allow_extending_visits: true | false;
  minutes_added_per_time_extension: number;
  time_extension_product_id: number | null;
  visit_extension_calculation_method: Arrangement["visit_extension_calculation_method"];
  round_extension_product_id: number | null;
  visit_length: ArrangementRuleVisitLength;
  almost_done_reminder_enabled: boolean;
}

export type ArrangementGroupResponse = {
  id: number;

  name: string;

  maximum_per_ticket: number;
  maximum_order_rounds: number;
  max_per_round_per_cover: number;

  arrangement_group_products: {
    id: number;
    arrangement_group_id: number;
    product_id: number;
  }[];
  arrangement_group_menus: {
    id: number;
    arrangement_group_id: number;
    menukaart_id: number;
  }[];
  v5_credit_cost: "outside_arrangements" | "no_cost" | number;
};

export type PreDiningArrangement = Pick<Arrangement, "id" | "name" | "arrangementGroupIds"> & {
  origin: { name: "table" | "zone" | "salesarea" | "testing"; id: number };
  id: number;
};

export type FetchArrangementsSettingsResponse = {
  data: {
    arrangements: ArrangementResponse[];
    supplementary_arrangements: Pick<
      ArrangementResponse,
      "id" | "name" | "arrangement_arrangement_groups" | "arrangement_products"
    >[];
    arrangement_groups: ArrangementGroupResponse[];
    pre_dining_arrangement: Pick<ArrangementResponse, "id" | "name" | "arrangement_arrangement_groups"> & {
      origin: {
        name: "table" | "zone" | "salesarea" | "testing";
        id: number;
      };
      id: number;
    };
  };
} & JamezzApiResponse;
export type SupplementaryArrangement = Pick<
  Arrangement,
  "id" | "name" | "arrangementArticleIds" | "arrangementGroupIds"
>;
export type Arrangement = {
  id: number;
  name: string;

  arrangementArticleIds: Record<string, boolean>;
  arrangementGroupIds: Record<string, boolean>;

  max_number_of_items_per_round_per_cover: number;
  minutes_between_every_round: number;
  max_rounds: number;
  max_time_in_minutes: number;
  visit_length: ArrangementRuleVisitLength;

  allow_extending_visits: boolean;
  visit_extension_calculation_method: "per_table" | "per_couvert";
  round_extension_product_id: null | number;
  time_extension_product_id: null | number;
  minutes_added_per_time_extension: number;
  almost_done_reminder_enabled: boolean;
};

export interface ArrangementsState {
  arrangements: Record<string, Arrangement>;
  arrangementGroups: Record<string, ArrangementGroup>;
  orderTimeElapsed: boolean;
  ticket_pincode: {
    status: "PINCODE_IS_REQUIRED" | "NO_PINCODE_SET_YET" | undefined;
    pincode: string;
  };
}

export enum ArrangementsLoadingState {
  LOADING = "loading",
  LOADED = "loaded",
  ERR_NO_ACTIVE_ARRANGEMENT = "error_no_active_arrangement",
  /**
   * When arrangements aren't enabled at all
   */
  ARRANGEMENT_MODULE_INACTIVE = "arrangement_module_inactive",
  /**
   * When something crucial has an error
   */
  ARRANGEMENT_FATAL_ERROR = "arrangement_fatal_error",
}

export type TableItem = {
  id?: number;
  apiId: string;
  count: number;
  name?: string;
};
export type TicketItem = {
  apiId: string;
  count: number;
  product: {
    name: string;
    price: number;
    id: number;
  };
};

export enum ORDER_STATUS {
  PENDING = "PENDING",
  FAILED = "FAILED",
  RETRYING = "RETRYING",
  SUCCEEDED = "SUCCEEDED",
}

export interface TicketOrder {
  id: number;
  count_as_round: boolean;
  by_app_user: boolean;
  created_at: string;
  items: TicketItem[];
  orderStatus: ORDER_STATUS;
}

export interface POSTicketOrder extends TicketOrder {
  updated_at: string;
  ticket_id: number;
  by_app_user: false;
  count_as_round: false;
}

export type Ticket = {
  id: number;
  is_open: boolean;
  webcode: string;
  ticket_orders?: (TicketOrder | POSTicketOrder)[];
  created_at: string;
  table_state_retrieved_at: string;
};
