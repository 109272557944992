import store, { useAppSelector } from "../redux/store";
import { useEffect } from "react";
import { Channel } from "laravel-echo";
import md5 from "md5";
import { useLazyGetShoppingCartConfirmationQuery } from "../redux/api/shoppingCartApi";
import { shoppingCartConfirmationResponseReceived } from "../redux/shoppingCartSlice";
import { menuUpdatedAt } from "../redux/menuDataSlice";
import { useFetchTableStateQuery, useLazyFetchTableStateQuery } from "../redux/api/arrangementsApi";
import { useEcho } from "../Websockets.tsx";

export default function useWebsocketChannel() {
  const echo = useEcho();
  const uuid = useAppSelector((state) => state.shoppingCart.shoppingCartConfirmation.requestSentForUuid);

  const [fetch] = useLazyGetShoppingCartConfirmationQuery({});
  useEffect(() => {
    let channel: Channel | null = null;
    if (uuid) {
      channel = echo.channel(md5("ShoppingCartConfirmation:" + uuid));
      if (channel) {
        channel.listen(".ShoppingCartConfirmationUpdated", async () => {
          fetch(uuid).then((response) => {
            if (response.data) {
              store.dispatch(shoppingCartConfirmationResponseReceived(response.data.data));
            }
          });
        });
      }
    }

    return () => {
      if (channel) {
        echo.leaveChannel(md5("ShoppingCartConfirmation:" + uuid));
      }
    };
  }, [echo, fetch, uuid]);
}

export function useMenuUpdatedListener() {
  const echo = useEcho();
  const menukaartVestigingId = useAppSelector((state) => state.global.salesarea.menukaartVestigingId);

  useEffect(() => {
    let channel: Channel | null = null;
    if (menukaartVestigingId) {
      channel = echo.channel("MenuUpdatedEvent-" + menukaartVestigingId);
      if (channel) {
        channel.listen(".MenuUpdated", async ({ menu_updated_at }: { menu_updated_at: string }) => {
          store.dispatch(menuUpdatedAt(menu_updated_at));
        });
      }
    }

    return () => {
      if (channel) {
        echo.leaveChannel("MenuUpdatedEvent-" + menukaartVestigingId);
      }
    };
  }, [echo, menukaartVestigingId]);
}

export function useTicketUpdatedListener() {
  const echo = useEcho();

  const useArrangements = useAppSelector((state) => state.global.salesarea.use_arrangements);
  const pincode = useAppSelector((state) => state.arrangements.ticket_pincode.pincode);
  const { data } = useFetchTableStateQuery(pincode, { skip: !useArrangements });
  const [fetch] = useLazyFetchTableStateQuery();
  useEffect(() => {
    let channel: Channel | null = null;
    if (data?.data?.ticket?.id) {
      channel = echo.channel("TicketUpdatedEvent-" + data?.data?.ticket?.id + "-" + data?.data?.ticket?.webcode);
      if (channel) {
        channel.listen(".TicketUpdated", () => {
          fetch(pincode);
        });
      }
    }

    return () => {
      if (channel) {
        echo.leaveChannel("TicketUpdatedEvent-" + data?.data?.ticket?.id + "-" + data?.data?.ticket?.webcode);
      }
    };
  }, [echo, data?.data?.ticket?.id, fetch, data?.data?.ticket?.webcode, pincode]);
}
