import {
  arrangementsApi,
  useFetchTableStateQuery,
  useLazyFetchArrangementsSettingsQuery,
} from "../../global/utils/redux/api/arrangementsApi";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../global/utils/redux/store";
import { ORDER_STATUS, POSTicketOrder, TicketOrder } from "../../types/qr/arrangements.ts";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz.tsx";
import { OrderRound } from "../components/Arrangements/rounds/OrderRound.tsx";
import { initOrderArticle } from "../../global/utils/models/order/OrderArticle.ts";
import { selectArticlesMap } from "../../global/utils/redux/selectors/selectArticlesMap.ts";

export function ArrangementsLoader() {
  const tableState = useAppSelector(
    (state: any) =>
      arrangementsApi.endpoints.fetchTableState.select(state.arrangements.ticket_pincode.pincode)(state).data
  );

  const useArrangements = useAppSelector((state) => state.global.salesarea.use_arrangements);
  const pincode = useAppSelector((state) => state.arrangements.ticket_pincode.pincode);
  useFetchTableStateQuery(pincode, {
    pollingInterval: import.meta.env.MODE == "development" ? 30_000 : 30_000,
    skip: !useArrangements,
  });

  const [fetchSettings, { data: responseSettings }] = useLazyFetchArrangementsSettingsQuery({
    pollingInterval: 120000,
  });

  useEffect(() => {
    if (useArrangements) {
      if (!responseSettings) {
        fetchSettings();
      }
    }
  }, [useArrangements, fetchSettings, responseSettings]);

  return tableState?.data?.ticket?.ticket_orders?.map((ticketOrder) => (
    <FailedOrder key={ticketOrder.id + ":" + (ticketOrder.by_app_user ? 1 : 0)} ticketOrder={ticketOrder} />
  ));
}

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function FailedOrder({ ticketOrder }: { ticketOrder: TicketOrder | POSTicketOrder }) {
  const [open, setOpen] = useState(false);
  const articlesMap = useAppSelector(selectArticlesMap);

  const previousOrderStatus = usePrevious<ORDER_STATUS>(ticketOrder.orderStatus);
  useEffect(() => {
    if (ticketOrder.orderStatus === "FAILED" && previousOrderStatus === "RETRYING") {
      setOpen(true);
    } else if (ticketOrder.orderStatus !== "FAILED") {
      setOpen(false);
    }
  }, [ticketOrder.orderStatus, previousOrderStatus]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <OrderRound
          id={ticketOrder.id}
          order_items={ticketOrder.items
            .filter((item) => item.product)
            .map((item) =>
              initOrderArticle(
                articlesMap,
                { ...articlesMap[item.product.id], name: item.product.name, price: item.product.price },
                item.count
              )
            )}
          count_as_round={ticketOrder.count_as_round}
          orderStatus={ticketOrder.orderStatus}
        />
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} onClick={() => setOpen(false)}>
          <FormattedMessageJamezz id={"Close"} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
