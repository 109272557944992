import { ButtonBase, IconButton, Typography } from "@mui/material";
import { useMemo } from "react";
import OrderOptionGroup from "../../../../global/utils/models/order/OrderOptionGroup";
import OrderArticle from "../../../../global/utils/models/order/OrderArticle";
import useCurrency, { CurrencyLocation } from "../../../../global/utils/useCurrency";
import Image from "../../../../global/components/Image";
import clsx from "clsx";
import OptionItemSwitch from "./OptionItemSwitch";
import Box from "@mui/material/Box";
import MenuTag, { useHasFilteredAllergens } from "../../../../global/utils/models/menu/MenuTag";
import MenuTagView from "../../../../qr/components/MenuTagView";
import useMenuTagsForArticle from "../../../../global/utils/hooks/useMenuTagsForArticle";
import { useOptionTranslations } from "../../../../global/utils/translations/useOptionTranslations";
import { getArticleName, getArticlePrice } from "../../../../global/utils/models/menu/Article";
import _ from "lodash";
import { Info } from "@mui/icons-material";
import { useIntl } from "react-intl";
import JTooltip from "../../../components/JamezzMUI/JTooltip";
import { optionItemMenuTagsStyles } from "../OptionGroups/OptionGroupCountable";
import useActiveMenuTags from "../../../../global/utils/menu/useActiveMenuTags";
import ArticleInformationDialog from "../../../components/ArticleInformationDialog/ArticleInformationDialog";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider";
import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { selectAppLanguage } from "../../../components/LanguageSelector/useLanguage.ts";
import useNestedOptionGroupsDialog from "../NestedOptions/useNestedOptionGroupsDialog.tsx";
import { selectSalesAreaPriceLineId } from "../../../../global/utils/useSalesAreaPriceLineId.ts";

export default function OptionItemRadio(props: {
  orderArticle: OrderArticle;
  value: string;
  onChange: (selectedOrderArticle: OrderArticle) => void;
  columnIndex: number;
  orderOptionGroup: OrderOptionGroup;
}) {
  const { translate } = useOptionTranslations();
  const toCurrency = useCurrency({ location: CurrencyLocation.ArticleDetail, prefixWithSign: true });
  const menuTags = useMenuTagsForArticle(props.orderArticle.article);

  const selected = useMemo(() => {
    return props.value === props.orderArticle.article.id;
  }, [props.value, props.orderArticle]);
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);

  const lang = useAppSelector(selectAppLanguage);

  const intl = useIntl();
  const isNotAvailable = props.orderArticle.article.isNotAvailable || props.orderArticle.article.isOutOfStock;
  const activeMenuTags = useActiveMenuTags(props.orderArticle.article);
  const hasFilteredAllergen = useHasFilteredAllergens(activeMenuTags);
  const { openDialog } = useDialog();
  const price = getArticlePrice(props.orderArticle.article, { salesAreaPriceLineId: priceLineId });
  const hasPrice = price !== 0;
  const openNestedOptionGroups = useNestedOptionGroupsDialog();

  return (
    <>
      <Box
        sx={{
          display: "contents",
          width: 1,
          filter: hasFilteredAllergen ? "opacity(0.6)" : "",
        }}
        className={clsx(
          selected && "JS-OptionItemRadio-Button-Selected",
          "JS-OptionItemRadio-Root",
          "JS-OptionItemGeneric-Root"
        )}
      >
        <JTooltip title={isNotAvailable ? intl.formatMessage({ id: "OptionItem.notAvailable" }) : ""}>
          <ButtonBase
            className={clsx(selected && "JS-OptionItemRadio-Button-Selected", "JS-OptionItemRadio-Button")}
            sx={{
              width: 1,
              borderRadius: 4,
              gridArea: `${1} / ${props.columnIndex + 1} / ${6} / ${props.columnIndex + 2}`,
              minHeight: 48,
              backgroundColor: selected ? "primary.light" : null,
              color: selected ? "white" : null,
              // border: !selected ? "1px solid lightgrey" : undefined,
              // looks better if text is centered when there's only a title.
              // otherwise, end looks nicer.
              // Disagree, beter to use start ... with max height for image
              opacity: isNotAvailable ? "0.5" : "auto",
              cursor: isNotAvailable ? "default" : "auto",
              filter: hasFilteredAllergen ? "opacity(0.6)" : "",
              boxShadow: "0 1px 10px 0px rgba(0,0,0,0.2)",
            }}
            data-cy={
              "OptionItem-Button-" +
              getArticleName(props.orderArticle.article, lang) +
              "-" +
              props.orderOptionGroup.optionGroup.name
            }
            disableTouchRipple={isNotAvailable}
            onClick={async () => {
              if (isNotAvailable) return;
              const orderArticle = await openNestedOptionGroups(props.orderArticle);

              if (orderArticle) {
                props.onChange(orderArticle);
              }
            }}
          ></ButtonBase>
        </JTooltip>
        {props.orderArticle.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset ? (
          <Box
            sx={{
              // // marginTop: 2.5,
              // borderTopLeftRadius: 16,
              // borderTopRightRadius: 16,
              overflow: "hidden",
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              pointerEvents: "none",
              zIndex: 1,

              height: "200px",
              width: 1,

              gridArea: `${1} / ${props.columnIndex + 1} / ${2} / ${props.columnIndex + 2}`,
              filter: hasFilteredAllergen ? "opacity(0.6)" : "",
            }}
          >
            <Image
              srcSet={props.orderArticle.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              className={"JS-OptionItemRadio-Image"}
            />
          </Box>
        ) : null}
        {props.orderArticle.article.description ? (
          <Box
            sx={{
              height: "200px",
              pointerEvents: "none",
              zIndex: 1,
              width: 1,
              gridArea: `${1} / ${props.columnIndex + 1} / ${2} / ${props.columnIndex + 2}`,
              display: "inline-flex",
              alignItems: "flex-start",
              justifyContent: "flex-end",
              filter: hasFilteredAllergen ? "opacity(0.6)" : "",
            }}
          >
            <IconButton
              sx={{ pointerEvents: "all" }}
              onClick={(e) => {
                e.stopPropagation();
                openDialog({
                  children: <ArticleInformationDialog article={props.orderArticle.article} />,
                });
              }}
            >
              <Info />
            </IconButton>
          </Box>
        ) : null}

        <Box
          sx={{
            pointerEvents: "none",
            zIndex: 1,
            paddingX: 1,
            textAlign: "center",
            marginTop: 2,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            wordBreak: "break-word",
            gridArea: `${2} / ${props.columnIndex + 1} / ${3} / ${props.columnIndex + 2}`,
            filter: hasFilteredAllergen ? "opacity(0.6)" : "",
          }}
        >
          <Typography
            className={clsx("JS-OptionItemRadio-Text", selected ? "JS-OptionItemRadio-Text-selected" : null)}
            variant={"subtitle2"}
            sx={{ color: selected ? "primary.contrastText" : undefined, px: 1 }}
          >
            {!_.isEmpty(props.orderArticle.article.translations)
              ? getArticleName(props.orderArticle.article, lang)
              : translate(props.orderArticle.article.name)}
            {isNotAvailable ? (
              <>
                <br />
                <Info fontSize="small" />
              </>
            ) : null}
          </Typography>
        </Box>
        {!isNotAvailable ? (
          <OptionItemSwitch
            selected={selected && false}
            sx={{
              pointerEvents: "none",
              zIndex: 1,
              gridArea: `${3} / ${props.columnIndex + 1} / ${4} / ${props.columnIndex + 2}`,
              width: "100%",
              // borderRadius: 8,
              // minHeight: 48,
              borderWidth: 0,
              borderColor: selected ? "transparent" : "grey.500",
              borderStyle: "solid",
              visibility: price === 0 && "collapse",
              filter: hasFilteredAllergen ? "opacity(0.6)" : "",
            }}
            className={clsx("JS-OptionItemRadio-Price", selected ? "JS-OptionItemRadio-Price-selected" : null)}
          >
            {hasPrice ? (
              <Typography
                variant={"subtitle2"}
                sx={{
                  fontSize: 24,
                  fontStyle: "italic",
                  opacity: 0.6,
                  color: selected ? "primary.contrastText" : undefined,
                }}
              >
                {toCurrency(price)}
              </Typography>
            ) : null}
          </OptionItemSwitch>
        ) : null}
        {menuTags?.length > 0 ? (
          <Box
            sx={{
              ...optionItemMenuTagsStyles(),
              gridArea: `${4} / ${props.columnIndex + 1} / ${5} / ${props.columnIndex + 2}`,
              filter: hasFilteredAllergen ? "opacity(0.6)" : "",
            }}
          >
            {menuTags.map((tag: MenuTag) => {
              return (
                <MenuTagView
                  key={tag.id}
                  menuTag={tag}
                  width={48}
                  margin={"4px"}
                  clickable={false}
                  placement={"top-end"}
                />
              );
            })}
          </Box>
        ) : null}
      </Box>
    </>
  );
}
