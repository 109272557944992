import { useIntl } from "react-intl";
import Box from "@mui/material/Box";
import { Button, Collapse, TextField, Typography, useTheme } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency.tsx";

import { useTipDrawer } from "../../../global/utils/useTipping.ts";
import isKiosk from "../../../global/utils/helpers/isKiosk.tsx";
import { onFocusKeyboard } from "../../../global/utils/redux/globalSlice.tsx";
import store from "../../../global/utils/redux/store.tsx";
import { useEffect, useState } from "react";

/**
 *
 * @param processTip Called when the tip drawer is closed.
 *                   tip parameter is set to 0 if user doesn't want to tip
 * @param open Open/close dialog
 * @constructor
 */
export function TipDrawerBodyKiosk({
  onTipIsAdded,
  paymentAmount,
}: {
  onTipIsAdded: (tipAmount: number) => void;
  paymentAmount: number;
}) {
  const {
    formatCurrency,
    customSelected,
    errorMessage,
    tippingIsAvailable,
    customTexts,
    firstPercentage,
    secondPercentage,
    thirdPercentage,
    amountFirstPercentage,
    amountSecondPercentage,
    amountThirdPercentage,
    chosenTipAmount,
    onChangeCustomTextField,
    onCustomCanceled,
    onCustomSelected,
    onPercentageSelected,
    onConfirm,
    onDontTip,
  } = useTipDrawer({
    onTipIsAdded,
    paymentAmount,
  });

  const [customText, setCustomText] = useState("");

  const theme = useTheme();

  useEffect(() => {
    if (!customSelected) {
      setCustomText("");
    }
  }, [customSelected]);
  const intl = useIntl();

  return tippingIsAvailable ? (
    /* z-index necessary to show over a dialog. */

    <Box
      sx={{
        sm: { padding: 1 },
        display: "grid",
        justifyItems: "center",
        padding: 8,
        backgroundColor: theme.palette.primary.dark,
      }}
    >
      <Typography
        variant="h5"
        sx={{ paddingBottom: 8, textAlign: "center", color: theme.palette.primary.contrastText }}
        className={"JS-Tip-Title"}
      >
        {customTexts["add_tip_message"] ?? <FormattedMessageJamezz id={"TipDrawer.title.express_thanks_blurb"} />}
      </Typography>

      {errorMessage ? (
        <Typography sx={{ paddingBottom: 8, color: (theme) => theme.palette.error.main }}>{errorMessage}</Typography>
      ) : null}

      <Box
        sx={{
          display: "grid",
          gridGap: 36,
          gridTemplateColumns: "repeat(6, 1fr)",
        }}
      >
        {customSelected ? (
          <>
            <TextField
              sx={{ gridColumn: "1 / span 4" }}
              value={customText}
              placeholder={formatCurrency(amountThirdPercentage).toString()}
              onChange={(e) => {
                const number = Number(e.target.value);
                setCustomText(e.target.value);
                if (!Number.isNaN(number)) {
                  onChangeCustomTextField(e);
                }
              }}
              slotProps={{
                htmlInput: {
                  style: {
                    color: theme.palette.primary.contrastText,
                  },
                },
              }}
              autoFocus
              onFocus={() => {
                if (isKiosk()) {
                  store.dispatch(
                    onFocusKeyboard({
                      value: customText,
                      layout: "decimal",
                    })
                  );
                }
              }}
            />
            <Button
              sx={{ gridColumn: "5 / span 2", color: theme.palette.primary.contrastText }}
              variant="contained"
              onClick={onCustomCanceled}
              className={"JS-Tip-Custom-Cancel_Button"}
            >
              <FormattedMessageJamezz id="Cancel" />
            </Button>
          </>
        ) : (
          <>
            <TipPercentageButton
              chosenTipAmount={chosenTipAmount}
              percentage={firstPercentage}
              amountPercentage={amountFirstPercentage}
              onPercentageSelected={onPercentageSelected}
            />
            <TipPercentageButton
              chosenTipAmount={chosenTipAmount}
              percentage={secondPercentage}
              amountPercentage={amountSecondPercentage}
              onPercentageSelected={onPercentageSelected}
            />
            <TipPercentageButton
              chosenTipAmount={chosenTipAmount}
              percentage={thirdPercentage}
              amountPercentage={amountThirdPercentage}
              onPercentageSelected={onPercentageSelected}
            />

            <Box sx={{ gridRow: "2", gridColumn: "1 / span 6", display: "flex", justifyContent: "center" }}>
              <Button
                className={"JS-Tip-Custom_Button"}
                sx={{ color: theme.palette.primary.contrastText }}
                variant="outlined"
                onClick={onCustomSelected}
              >
                <FormattedMessageJamezz id="TipDrawer.button.custom_amount" />
              </Button>
            </Box>
          </>
        )}
        <Collapse
          in={Number(chosenTipAmount) > 0}
          sx={{
            color: theme.palette.primary.contrastText,
            gridColumn: "1 / span 6",
            gridRow: "3",
            marginY: 2,
            ".MuiCollapse-wrapperInner": { display: "flex", justifyContent: "center" },
          }}
        >
          <Button
            className={"JS-Tip-Confirm-Button"}
            variant={"contained"}
            onClick={onConfirm}
            sx={{ color: theme.palette.primary.contrastText }}
          >
            <FormattedMessageJamezz
              id="TipDrawer.button.confirmTip"
              values={{
                tipAmount:
                  Number(chosenTipAmount) <= 0
                    ? intl.formatMessage({
                        id: "TipDrawer.current_tip_has_no_value",
                      })
                    : formatCurrency(Number(chosenTipAmount)),
              }}
            />
          </Button>
        </Collapse>
        <Button
          className={"JS-Tip-Dont-Tip-Button"}
          sx={{ gridColumn: "2 / span 4", gridRow: "4", color: theme.palette.primary.contrastText }}
          color={"primary"}
          variant={"outlined"}
          onClick={onDontTip}
        >
          <FormattedMessageJamezz id="TipDrawer.button.dont_tip" />
        </Button>
      </Box>
    </Box>
  ) : null;
}

function TipPercentageButton({
  chosenTipAmount,
  percentage,
  amountPercentage,
  onPercentageSelected,
}: {
  chosenTipAmount: string;
  percentage: number;
  amountPercentage: number;
  onPercentageSelected: (percentage: number) => void;
}) {
  const formatCurrency = useCurrency({ location: CurrencyLocation.Checkout });
  const theme = useTheme();
  return (
    <Button
      className={"JS-Tip-Percentage-Button"}
      color={"primary"}
      variant={String(amountPercentage) == chosenTipAmount ? "contained" : "outlined"}
      sx={{
        whiteSpace: "nowrap",
        gridColumn: "span 2",
        letterSpacing: "normal",
        color: theme.palette.primary.contrastText,
        border:
          String(amountPercentage) === chosenTipAmount
            ? `2px solid ${theme.palette.primary.contrastText}`
            : "2px solid transparent",
      }}
      onClick={() => {
        onPercentageSelected(amountPercentage);
      }}
    >
      <Typography>{formatCurrency(amountPercentage).toString()}</Typography>
      <Typography variant={"caption"}>&nbsp;({percentage}%)</Typography>
    </Button>
  );
}
