import { useGetLoggedinUserQuery, useLogoutMutation } from "../../../global/utils/redux/api/piggyAuthApi";
import { useFetchPointsBalanceQuery, useFetchRewardsQuery } from "../../../global/utils/redux/api/piggyApi";
import Box from "@mui/material/Box";
import { Button, Card, CardContent, Skeleton, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { PiggyRewardItem } from "./PiggyRewardItem";

import { useAppSelector } from "../../../global/utils/redux/store";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import { postAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics";
import { PiggyReward } from "../../../types/shared/piggy";

export function PiggyPage() {
  const { closeDialog } = useDialog();
  const [logout] = useLogoutMutation();

  const { data: loggedInUser } = useGetLoggedinUserQuery();
  const email = loggedInUser?.data.piggy_contact_email;
  const { data: balance, isLoading: balanceLoading } = useFetchPointsBalanceQuery(undefined, {
    skip: !loggedInUser || !loggedInUser?.data?.has_session,
  });
  const anItemHasBeenClaimed = useAppSelector((state) => state.piggy.anItemHasBeenClaimed);
  const { data: rewards, isLoading: rewardsLoading } = useFetchRewardsQuery();
  const customTexts = useCustomTexts(["loyalty rewards - description", "loyalty rewards - account title"]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // 2 * page margins
        height: "calc(100% - 64px)",
      }}
    >
      <Typography variant={"h6"} className={"JS-Piggy-AccountHeader"}>
        {customTexts["loyalty rewards - account title"] ?? (
          <FormattedMessageJamezz id={"Piggy.rewards.account.title"} />
        )}
      </Typography>
      {customTexts["loyalty rewards - description"] ? (
        <Typography variant="body1" sx={{ mb: 2 }}>
          {customTexts["loyalty rewards - description"]}
        </Typography>
      ) : null}
      <Card sx={{ my: 4 }}>
        <CardContent data-cy="piggy-card-points">
          <Typography sx={{ fontSize: 14 }} gutterBottom color="text.secondary">
            {email}
          </Typography>
          <Typography sx={{ mb: 4 }} fontWeight="bold" component="span" variant="h5">
            {balanceLoading ? (
              <Skeleton variant="text" width={60} sx={{ display: "inline-block" }} />
            ) : (
              <>
                {balance}
                <Typography sx={{ fontSize: 15 }} component="span">
                  <FormattedMessageJamezz id="Piggy.rewards.points" />{" "}
                </Typography>
              </>
            )}
          </Typography>
        </CardContent>
      </Card>
      <Typography variant="h6" className="JS-Piggy-RewardsHeader">
        <FormattedMessageJamezz id="Rewards" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessageJamezz id="Piggy.rewards.explanation.addRewardToOrder" />
      </Typography>
      <Box sx={{ width: 1, my: 2, flex: 1, overflow: "auto" }}>
        {rewardsLoading ? (
          <Skeleton variant="rectangular" />
        ) : rewards && rewards.data.length ? (
          rewards.data
            // articles without an artikelid cannot be ordered.
            // location needs to configure artikelid in piggy backoffice
            .filter((reward: PiggyReward) => reward.artikelid)
            .map((reward: PiggyReward) => {
              return (
                <Box sx={{ mb: 2 }} key={reward.id}>
                  <PiggyRewardItem item={reward} piggyPoints={balance} />
                </Box>
              );
            })
        ) : (
          <Typography color="warning" data-cy="piggy-message-norewards">
            <FormattedMessageJamezz id="Piggy.rewards.noRewardsAvailable" />
          </Typography>
        )}
      </Box>

      <Box
        component="div"
        sx={{
          textAlign: "center",
          display: "flex",
          gap: "8px",
        }}
      >
        <Button
          sx={{ flex: "2 1 0" }}
          variant="contained"
          onClick={() => {
            postAnalyticsEvent({
              category: "PiggyPage",
              action: "closeDialog",
            });
            closeDialog();
          }}
        >
          <FormattedMessageJamezz id="Continue ordering" />
        </Button>
        {anItemHasBeenClaimed ? null : (
          <Button
            sx={{
              flex: "1 1 0",
            }}
            data-cy="piggy-btn-logout"
            variant="outlined"
            onClick={() => {
              logout();
            }}
          >
            <FormattedMessageJamezz id="Piggy.auth.btn.logout" />
          </Button>
        )}
      </Box>
    </Box>
  );
}
