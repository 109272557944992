import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Article from "../../../../global/utils/models/menu/Article";

import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import { Translations, upsell_translation_keys } from "../../../../types/shared/language";
import DiscountedProductItem from "./DiscountedProductItem.tsx";
import { voucherOrderArticleCanceled, VoucherV2 } from "../../../../global/utils/vouchersV2/vouchersV2Slice.tsx";
import store from "../../../../global/utils/redux/store.tsx";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider.tsx";

export default function AddDiscountedProductDialog({
  articles,
  title,
  text,
  voucher,
}: {
  articles: Article[];
  title?: string;
  text?: string;
  upsellType?: string; // export type UpsellType = "MenuUpsell" | "Product" | etc.
  upsellSource?: string | null;
  translations?: Translations<upsell_translation_keys>;
  onClose?: () => void;
  voucher: VoucherV2;
}) {
  const { closeDialog } = useDialog();
  // const translations = useSelectTranslations(translations);

  // const text = translations["onceText"] ?? text;

  return (
    <Box
      data-cy="upsell-dialog"
      sx={{
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 4,
      }}
    >
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography
          variant={"h5"}
          sx={{ fontStyle: "italic", fontWeight: 800, px: 1, textAlign: "center" }}
          className={"JS-ArticleSuggestionPage-Title"}
        >
          {title ?? <FormattedMessageJamezz id={"Pick your item"} />}
        </Typography>
        {text ? (
          <Typography variant={"subtitle1"} className={"JS-ArticleSuggestionPage-Text"}>
            {text}
          </Typography>
        ) : null}
        <Grid container sx={{ flex: "1 1 0%", alignContent: "flex-start", justifyContent: "center" }}>
          {articles.map((article) => {
            return (
              <DiscountedProductItem
                key={article.id}
                article={article}
                addToShoppingCart={voucher.voucherdef.discountType !== "ADD_DISCOUNTED_PRODUCT"}
                voucher={voucher}
              />
            );
          })}
        </Grid>
      </Box>
      <Button
        sx={{ position: "sticky", bottom: 12 }}
        variant={"contained"}
        onClick={() => {
          store.dispatch(voucherOrderArticleCanceled({ voucher }));
          closeDialog();
        }}
      >
        <FormattedMessageJamezz id={"Cancel"} />
      </Button>
    </Box>
  );
}
