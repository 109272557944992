import { useAppDispatch, useAppSelector } from "../redux/store";
import { useEffect } from "react";
import Papa from "papaparse";
import { AiRecord, clearAiRecords, setAiRecords } from "../redux/aiDataSlice.tsx";
import Article from "../models/menu/Article.ts";
import { getArticleByHash } from "../models/menu/Menu.ts";

export function getUpsellItems(
  aiUpsellData: AiRecord[] | null,
  articleHashNamesMap: Record<string, Article>,
  max_suggestions: number
) {
  const findProducts: string[] = [];
  const articles: Article[] = [];
  if (aiUpsellData) {
    aiUpsellData.forEach((aiUpsellRecord: AiRecord) => {
      if (aiUpsellRecord.recommendations?.product_hash_1)
        findProducts.push(aiUpsellRecord.recommendations?.product_hash_1);
      if (aiUpsellRecord.recommendations?.product_hash_2)
        findProducts.push(aiUpsellRecord.recommendations?.product_hash_2);
      if (aiUpsellRecord.recommendations?.product_hash_3)
        findProducts.push(aiUpsellRecord.recommendations?.product_hash_3);
      if (aiUpsellRecord.recommendations?.product_hash_4)
        findProducts.push(aiUpsellRecord.recommendations?.product_hash_4);

      findProducts.forEach((productHash: string) => {
        const addArticle = getArticleByHash(articleHashNamesMap, productHash);
        if (addArticle && !articles.includes(addArticle) && articles.length < max_suggestions && addArticle.canOrder) {
          articles.push(addArticle);
        }
      });
    });
  }

  return articles;
}
export default function useDDAiData() {
  const aiDataResource = useAppSelector((state) => state.global.salesarea?.custom_data?.ai?.recommendation_url ?? null);
  const aiDataDebugging = useAppSelector((state) => state.global.salesarea?.custom_data?.ai?.debugging ?? false);
  const menucardSalesareaId = useAppSelector(
    (state) => state.global.salesarea?.menukaartVestigingId ?? state.global.salesarea?.id
  );
  const salesareaId = useAppSelector((state) => state.global.salesarea?.id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    /**
     * Load CSV data from url
     */
    let notified = false;
    if (aiDataDebugging) {
      console.log("Resource file: " + aiDataResource);
      console.log("Menucard salesarea: " + menucardSalesareaId);
      console.log("Current salesarea: " + salesareaId);
    }
    if (aiDataResource) {
      dispatch(clearAiRecords());
      Papa.parse(aiDataResource, {
        download: true,
        // @ts-expect-error PaPa Parse had er geen zin in
        complete: function (results: { data: [] }) {
          const aiRecords: Array<AiRecord> = [];
          results.data.forEach((row: [string, string, string, string, string, string, string]) => {
            const workLocation = parseInt(row[0]) ?? null;
            if (
              workLocation == menucardSalesareaId ||
              workLocation == salesareaId ||
              import.meta.env.MODE == "development"
            ) {
              const recommendationArray: string[] = (row[5] ?? "").split(";");
              const newAiRecord: AiRecord = {
                location: workLocation,
                product_hash_1: row[1] ?? null,
                product_1_in_basket: false,
                product_hash_2: row[2] ?? null,
                product_2_in_basket: false,
                product_hash_3: row[3] ?? null,
                product_3_in_basket: false,
                product_hash_4: row[4] ?? null,
                product_4_in_basket: false,
                required_products: (row[1] ? 1 : 0) + (row[2] ? 1 : 0) + (row[3] ? 1 : 0) + (row[4] ? 1 : 0),
                upsell_displayed: false,
                recommendations: {
                  product_hash_1: recommendationArray[1] ?? null,
                  product_hash_2: recommendationArray[2] ?? null,
                  product_hash_3: recommendationArray[3] ?? null,
                  product_hash_4: recommendationArray[4] ?? null,
                },
                dlu: row[6],
              };
              aiRecords.push(newAiRecord);
              if (aiDataDebugging) {
                console.log(newAiRecord);
              }
            } else if (workLocation) {
              if (!notified) {
                console.log(
                  "Invalid AI data file found, salesarea ids don't match (" +
                    menucardSalesareaId +
                    " != " +
                    workLocation +
                    ")"
                );
                notified = true;
              }
            }
          });
          if (aiRecords.length > 0) {
            dispatch(setAiRecords(aiRecords));
          }
        },
      });
    }
  }, [dispatch, aiDataResource, menucardSalesareaId]);
}
