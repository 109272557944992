import { useCallback } from "react";
import store from "./redux/store";
import { useLazyFindVoucherV2Query } from "./vouchersV2/api/vouchersApi.tsx";
import { vouchersV2Added, VoucherV2 } from "./vouchersV2/vouchersV2Slice.tsx";
import { canAddNewVoucher } from "./vouchersV2/canAddNewVoucher.ts";
import { BlockUserInput } from "@jamezz/react-components";
import { toast } from "react-toastify";

export default function useVouchers(
  {
    withLoading = true,
    withToasts = true,
    doNotAddVoucherToStore = false,
  }: { withLoading?: boolean; withToasts?: boolean; doNotAddVoucherToStore?: boolean } = {
    withLoading: true,
    withToasts: true,
    doNotAddVoucherToStore: false,
  }
) {
  const [trigger, result] = useLazyFindVoucherV2Query();
  BlockUserInput.useBlockUserInput(result.isFetching && withLoading);

  return useCallback(
    (voucherCode: string, numberOfTimes: number) => {
      return new Promise<VoucherV2>((resolve, reject) => {
        trigger({ code: voucherCode, numberOfTimes, vouchersV2: store.getState().vouchersV2.vouchers })
          .then((response) => {
            if (response.isSuccess) {
              if (!doNotAddVoucherToStore) {
                const result = canAddNewVoucher(store.getState().vouchersV2.vouchers, response.data);
                if (!result.isError) {
                  resolve(response.data);
                  store.dispatch(vouchersV2Added([response.data]));
                } else {
                  toast.warning(result.message);
                  reject({ result: { message: result.message } });
                }
              }
            } else if (
              response.error &&
              "data" in response.error &&
              response.error.data &&
              typeof response.error.data == "object" &&
              "message" in response.error.data
            ) {
              reject({ result: { message: response.error?.data?.message ?? "Voucher error" } });
              if (withToasts && typeof response.error?.data?.message == "string") {
                toast.error(response.error?.data?.message ?? "Voucher error");
              }
            } else {
              reject("Unexpected error");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [doNotAddVoucherToStore, trigger, withToasts]
  );
}
