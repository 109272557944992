import { ComponentType, PropsWithChildren } from "react";
import { useAppSelector } from "../utils/redux/store.tsx";
import { Language } from "../../types/shared/language.ts";
import { IntlProvider } from "react-intl";
import { messagesForLanguages, selectAppLanguage } from "../../kiosk/components/LanguageSelector/useLanguage.ts";

export default function JamezzMultipleIntlProvider(
  props: PropsWithChildren & { messageId: string; Content: ComponentType<{ messageId: string; language: Language }> }
) {
  const languagesString = useAppSelector((state) => state.global.sessionState?.showLangselLanguages);
  const selectedLang = useAppSelector(selectAppLanguage);
  const languages = (languagesString ? (JSON.parse(languagesString) as Language[]) : null) ?? [];

  const sortedLanguages = languages.sort((a, b) => {
    if (a === "en") return -1;
    if (b === "en") return 1;

    if (a === selectedLang) return -1;
    if (b === selectedLang) return 1;

    return 0;
  });

  return (
    <>
      {sortedLanguages.map((language) => (
        <IntlProvider
          key={language}
          locale={language}
          messages={messagesForLanguages[language] || messagesForLanguages["en"]}
          onError={() => {}}
        >
          <props.Content messageId={props.messageId} language={language} />
        </IntlProvider>
      ))}
    </>
  );
}
